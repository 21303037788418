.title {
  position: relative;
  display: inline-block;
}

.title::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -8px; /* Adjust the distance of the underline from the text */
  width: 50%; /* Adjust the width of the underline */
  height: 5px; /* Adjust the thickness of the underline */
  background-color: #f04a00; /* Adjust the color of the underline */
}

.title:hover::after {
  transform-origin: bottom right;
  transform: scaleX(0);
  transition: transform 0.5s ease;
  width: 100%;
}
