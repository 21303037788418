.card {
  background-color: unset !important;
}

.cardImage {
  width: unset !important;
  --menu-width: 125px;
  padding-left: calc((100% - var(--menu-width)) / 2);
  padding-right: calc((100% - var(--menu-width)) / 2);
}
