.social-media-icons {
  position: absolute;
  left: -80px;
  background-color: #333;
  padding: 10px;
  transition: left 0.3s ease;
}

.social-media-icons.visible {
  left: 0;
}
